import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import ScrollAnimation from "../atoms/ScrollAnimation";
import {GatsbyImage} from "gatsby-plugin-image";
import RichTextConverter from "../atoms/RichTextConverter";

const Holder = styled.div`
  grid-column: 1/6;

  &:nth-child(even) {
    grid-column: 2/7;
  }

  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: 1/6;
    &:nth-child(even) {
      grid-column: 8/13;
      transform: translateY(7rem);
    }
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-column: 1/5;
    &:nth-child(even) {
      transform: translateY(7rem);
    }

    &:nth-child(2n) {
      grid-column: 9/13;
    }

    &:nth-child(3n) {
      grid-column: 2/6;
    }

    &:nth-child(4n) {
      grid-column: 8/12;
    }
  }
`;

const Inner = styled.div`
  h3 {
    margin: 1rem 0;
  }
`;

const ImageHolder = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

function WorkPreview({client}) {
  return (
    <Holder>
      <ScrollAnimation>
        <Inner>
          <ImageHolder>
            <GatsbyImage image={client.thumbnail.gatsbyImageData} alt={client.title} />
          </ImageHolder>
          <h3 className="p">{client.title}</h3>
          <div className="dashed-list">
            <RichTextConverter content={client.servicesProvided} />
          </div>
        </Inner>
      </ScrollAnimation>
    </Holder>
  )
}

WorkPreview.propTypes = {
  client: PropTypes.object,
};

export default WorkPreview;