import React from "react";
import Seo from "../components/molecules/Seo";
import Container from "../components/atoms/Container";
import styled from "styled-components";
import ScrollAnimation from "../components/atoms/ScrollAnimation";
import WhatWeDo from "../components/organisms/WhatWeDo";
import About from "../components/organisms/About";
import SelectedWork from "../components/organisms/SelectedWork";
import {graphql} from "gatsby";

const Holder = styled.div`
  transition: color 0.25s ease-in-out;
  &.active {
    color: ${props => props.theme.colours.white};
  }
`;

const IntroText = styled.p`
  max-width: 40rem;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem 0;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin: 8rem 0;
    min-height: auto;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    margin: 10rem 0;
  }
`;

const IndexPage = ({data}) => {
  const { introText, selectedClients, about, about2Heading, about2, clientList  } = data.contentfulFrontpage;
  return (
    <Holder id="home-content">
      <Seo title="Home" />
      <Container>
        <ScrollAnimation>
          <IntroText className="h1">{introText.introText}</IntroText>
        </ScrollAnimation>
        <WhatWeDo />
        <About intro={about.about} text1Heading={about2Heading} text1={about2.about2} clientList={clientList}/>
        <SelectedWork clients={selectedClients}/>
      </Container>
    </Holder>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query HomeQuery {
    contentfulFrontpage {
      clientList
      about2 {
        about2
      }
      about {
        about
      }
      about2Heading
      introText {
        introText
      }
      selectedClients {
        id
        servicesProvided {
          raw
        }
        title
        thumbnail {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
`
