import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import WorkPreview from "../molecules/WorkPreview";

const Holder = styled.div`
  margin: 4rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    margin: 6rem 0;
  }
  h2 {
    margin: 0 0 2rem 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6,1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 4rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: repeat(12,1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 6rem;
    padding-bottom: 7rem;
  }
  @media( ${props => props.theme.breakpoints.lg} ) {
    grid-row-gap: 8rem;
  }
`;

function SelectedWork({clients}) {
  return (
    <Holder>
      <h2 className="p">Selected Work</h2>
      <Grid>
        {clients.map(client => <WorkPreview key={client.id} client={client}/>)}
      </Grid>
    </Holder>
  )
}

SelectedWork.propTypes = {
  clients: PropTypes.array,
};

export default SelectedWork;