import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from "gatsby-source-contentful/rich-text";
import styled from 'styled-components';
import GatsbyImage from "gatsby-image";

const Holder = styled.div`
  width: 100%;
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

class RichTextConverter extends Component {
  render() {
    const { content } = this.props;

    const options = {
      renderNode: {
        [ BLOCKS.EMBEDDED_ASSET ]: ( node ) => {
          // console.log(node.data);
          let {file, fluid} = node.data.target
          if( file.contentType.includes( 'video' ) ) {
            return <div className="video-holder">
              <video controls>
                <source src={file.url} type={file.contentType}/>
              </video>
            </div>
          }
          return (
            <div className="image-holder">
              <GatsbyImage fluid={fluid} />
            </div>
          )
        },
      },
      renderText: text =>
        text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
    };

    return (
      <Holder classNames="rich-text-holder">{renderRichText( content, options )}</Holder>
    )
  }
}

RichTextConverter.propTypes = {
  content: PropTypes.object
};

export default RichTextConverter;
