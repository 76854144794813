import React, { useEffect, useRef, useState } from 'react';
import _uniqueId from 'lodash/uniqueId';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';

gsap.registerPlugin( ScrollTrigger );

const values = {
  bottom: {
    y: 50,
  },
  left: {
    x: -50,
  },
  right: {
    x: 50,
  }
}

function ScrollAnimation({ children, from, delay } ) {
  const [ id, setId ] = useState( null );
  const holder = useRef( null );
  const tl = useRef( null );

  useEffect( () => {
    setId( _uniqueId( 'fade-up-holder-' ) );
  }, [] );

  useEffect( () => {
    if ( !tl.current && id ) {

      tl.current = ScrollTrigger.matchMedia( {
        // desktop
        '(min-width: 1025px)': function () {
          ScrollTrigger.saveStyles( `#${id}` );
          gsap.timeline( {
            scrollTrigger: {
              trigger: holder.current,
              start: 'top bottom',
              end: 'top 40%',
              scrub: 1,
            },
          } )
          .from( `#${id} `, {
            ...values[from],
            delay: delay,
            duration: 4,
            ease: 'power2.out',
          } );
        }
      } );

    }

  }, [ id, delay, from ] );

  return (
    <div className="scroll-animation-holder" ref={holder}>
      <div id={id}>{children}</div>
    </div>
  )
}

ScrollAnimation.propTypes = {
  from: PropTypes.oneOf([
    'left',
    'right',
    'bottom'
  ]),
  delay: PropTypes.number,
};

ScrollAnimation.defaultProps = {
  from: 'bottom',
  delay: 0,
};


export default ScrollAnimation;
