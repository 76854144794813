import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from "../atoms/ScrollAnimation";
import PropTypes from 'prop-types';

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  margin: 4rem 0;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 4rem;
    margin: 6rem 0;
  }
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-row-gap: 8rem;
    margin: 8rem 0;
  }

  h2 {
    ${(props) => props.theme.fluidType(0)};
  }

  .scroll-animation-holder > div > div {
    > :first-child { margin-top: 0; }

    > :last-child { margin-bottom: 0; }
  }
`;

const IntroText = styled.div`
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: span 10;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-column: span 7;
  }
`;

const Text1 = styled.div`
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: span 6;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-column: 1/6;
  }
`;

const Text2 = styled.div`
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: 8/13;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-column: 9/13;
  }
`;

function About({intro, text1Heading, text1, clientList}) {
  return (
    <Holder>
      <IntroText>
        <ScrollAnimation>
          <p className="h3">{intro}</p>
        </ScrollAnimation>
      </IntroText>
      <Text1>
        <ScrollAnimation>
          <h2>{text1Heading}</h2>
          <p>{text1}</p>
        </ScrollAnimation>
      </Text1>
      <Text2>
        <ScrollAnimation delay={2}>
          <h2>Client partners</h2>
          <ul className="dashed">
            {clientList && clientList.map((client, i) => <li key={i}>{client}</li>)}
          </ul>
        </ScrollAnimation>
      </Text2>
    </Holder>
  )
}

About.propTypes = {
  intro: PropTypes.string.isRequired,
  text1Heading: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  clientList: PropTypes.array.isRequired,
};

export default About;