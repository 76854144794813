import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import ElbowArrow from '../../assets/svg/elbowArrow.inline.svg';
import Bgs from "../molecules/Bgs";
import {gsap} from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

const items = [
  {title: 'Design', color: '#FFA4F6', bgColor: '#281608'},
  {title: 'Brand', color: '#13FFD5', bgColor: '#120D28'},
  {title: 'Product', color: '#B1FF00', bgColor: '#27330A'},
  {title: 'Digital', color: '#13FFD5', bgColor: '#003639'},
  {title: 'Strategy', color: '#FAFF00', bgColor: '#082439'},
  {title: 'Packaging', color: '#A3ACFE', bgColor: '#270035'},
  {title: 'Art Direction', color: '#8FEBFF', bgColor: '#360031'},
  {title: 'Print', color: '#FFB21E', bgColor: '#000A39'},
  {title: 'Development', color: '#8FF5B9', bgColor: '#1D1A06'},
  {title: 'Experience', color: '#CE98FF', bgColor: '#171717'},
]
const chunkedItems = [
  [items[0]],
  [items[1], items[2], items[3]],
  [items[4], items[5], items[6]],
  [items[7], items[8], items[9]],
]

const Holder = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Inner = styled.div`
  margin: 4rem 0;
  ${(props) => props.theme.fluidType(1)};
  width: 100%;

  @media( ${props => props.theme.breakpoints.md} ) {
    ${(props) => props.theme.fluidType(2)};
  }

  h2 {
    display: flex;
    align-items: center;

    svg {
      height: 1rem;
      width: auto;
      margin: 1rem 0 0 0.5rem;
    }
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 2rem);
  margin: 0 -1rem;
`;

const Chunk = styled.div`
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  &:nth-child(1) {
    margin-left: 15%;
  }
  &:nth-child(2) {
    justify-content: center;
    @media( ${props => props.theme.breakpoints.md} ) {
      margin-left: 5%;
    }
    @media( ${props => props.theme.breakpoints.lg} ) {
      align-self: center;
      margin-right: 5%;
      margin-left: 0;
    }
  }
  &:nth-child(3) {
    justify-content: center;
    @media( ${props => props.theme.breakpoints.md} ) {
      justify-content: flex-end;
      align-self: flex-end;
    }
    @media( ${props => props.theme.breakpoints.lg} ) {
      margin-right: 5%;
    }
  }
  &:nth-child(4) {
    > :first-child {
      margin-left: 10%;
      @media( ${props => props.theme.breakpoints.md} ) {
        margin-left: 0.5rem;
      }
    }
  }
`;

const Pill = styled.p`
  cursor: default;
  ${(props) => props.theme.fluidType(1)};
  margin: 1rem 0.5rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    ${(props) => props.theme.fluidType(2)};
    margin: 1.2rem 0.5rem;
  }
  
  span {
    padding: 0 1.2rem;
    line-height: 2.4rem;
    border: 1px solid;
    border-radius: 1.2rem;
    transition: all 0.25s ease-in-out;
    display: inline-block;
  }

  &:hover span {
    color: ${props => props.textColor};
    background-color: ${props => props.hoverColor};
    border-color: transparent;
    transform: rotate(${props => props.even ? '-15deg' : '15deg'});
  }
`;

function WhatWeDo() {
  const [current, setCurrent] = useState(null);
  const holder = useRef(null)
  const tl = useRef(null)

  useEffect(() => {
    if (!tl.current) {
      tl.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: holder.current,
            start: "top 80%",
            end: "bottom 20%",
            scrub: 1,
            toggleClass: {targets: ["#header", "#bgs", "#home-content"], className: "active"}
          },
        })
    }
  })

  return (
    <>
      <Bgs items={items} active={current} />
      <Holder ref={holder}>
        <Inner>
          <h2 className="h3"><span>What we do</span><ElbowArrow /></h2>
          <List>

            {chunkedItems.map((chunk, i) => <Chunk key={`chunk${i}`}>
              {chunk.map((item, index) => {
                  let realIndex = Math.max(3*i - (2-index),0);
                  return (<Pill
                    textColor={item.bgColor}
                    hoverColor={item.color}
                    even={realIndex % 2 !== 0}
                    key={realIndex}
                    onMouseEnter={() => setCurrent(realIndex)}
                    onMouseLeave={() => setCurrent(null)}><span>{item.title}</span></Pill>)
                }
              )}
            </Chunk>)}


          </List>
        </Inner>
      </Holder>
    </>
  )
}

export default WhatWeDo;