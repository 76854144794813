import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const Holder = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-color: ${props => props.theme.colours.black};
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  &.active {
    opacity: 1;
  }
`;

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${props => props.color};
  opacity: ${props => props.active ? 1 : 0};
  transition: opacity 0.25s ease-in-out;
`;

function Bgs({items, active}) {
  return (
    <Holder id="bgs">
      {items.map((item, i) =>
        <Bg key={i} color={item.bgColor} active={i === active} />
      )}
    </Holder>
  )
}

Bgs.propTypes = {
  items: PropTypes.array.isRequired,
    active: PropTypes.number,
};

export default Bgs;